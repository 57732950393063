/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    div: "div"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Your credit report reflects your financial condition. By learning to interpret a credit report, you can acquire a deeper insight into your financial health. Although it might appear challenging to analyze a credit report, simplifying the data contained within your credit reports is easier than it seems."
    }), "\n", _jsx(_components.p, {
      children: "Essentially, your credit report is an exhaustive summary of your credit history, used to calculate your credit score and inform financial decisions by creditors and financial institutions. The most valuable tip regarding credit reports? Grasp the fundamentals of reading credit reports and annually verify for inaccuracies."
    }), "\n", _jsx(_components.h2, {
      children: "Personal Information"
    }), "\n", _jsx(_components.p, {
      children: "This information is used to verify your identity. Used by KIB, lenders and banks. If any of this information is incorrect then it must be updated immediately."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/ea09aaef95c2d46c02ebc5057b1e6a9f/f2d92/article3_image1.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 21.62162162162162%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAYUlEQVQY042PjQrAIAiE9/6PWiwaCf1o3bARbFDbDg6R0w/daq3w/oC1FrtzMMYgBEJrDZrNrNnKm4ggxohSSh/OOYOIetVe87sHcKYHMKV0AVjAzMulN3XguEIhf1768glrtTwzLc/8PgAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Personal Information Example\"\n        title=\"\"\n        src=\"/static/ea09aaef95c2d46c02ebc5057b1e6a9f/fcda8/article3_image1.png\"\n        srcset=\"/static/ea09aaef95c2d46c02ebc5057b1e6a9f/12f09/article3_image1.png 148w,\n/static/ea09aaef95c2d46c02ebc5057b1e6a9f/e4a3f/article3_image1.png 295w,\n/static/ea09aaef95c2d46c02ebc5057b1e6a9f/fcda8/article3_image1.png 590w,\n/static/ea09aaef95c2d46c02ebc5057b1e6a9f/efc66/article3_image1.png 885w,\n/static/ea09aaef95c2d46c02ebc5057b1e6a9f/f2d92/article3_image1.png 1027w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Creditinfo Predictor (CIP)"
    }), "\n", _jsx(_components.p, {
      children: "The Creditinfo Predictor is a result of the analysis of all the subject's credit related information which is stored in the database of the credit bureau. It is meant to shorten the information by summarizing it into a single number that is represented both qualitatively and quantitatively. The CIP Score is the quantitative measure which ranges from 250 to 900 where higher number is referred to the lower risk."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/fb2d8a4decfa50838d6b5bcca2f5c92c/20c85/article3_image2.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 15.54054054054054%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfElEQVQI12WO2woDIQxE/f+fbN8Ub1WUxPuUtXSXbR8OhOQwGeFfHsYYOOdgrEGIEa019N5vjDHOec75d/86QikFay1yzogxbo7AWusJM0NKiRACSil4ygeI6eZsWoPw/tPwCCUilFp2g4u1P2utkVLaO+00iBlrrR934g1d9ejpNNMRCgAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Creditinfo Predictor Example\"\n        title=\"\"\n        src=\"/static/fb2d8a4decfa50838d6b5bcca2f5c92c/fcda8/article3_image2.png\"\n        srcset=\"/static/fb2d8a4decfa50838d6b5bcca2f5c92c/12f09/article3_image2.png 148w,\n/static/fb2d8a4decfa50838d6b5bcca2f5c92c/e4a3f/article3_image2.png 295w,\n/static/fb2d8a4decfa50838d6b5bcca2f5c92c/fcda8/article3_image2.png 590w,\n/static/fb2d8a4decfa50838d6b5bcca2f5c92c/efc66/article3_image2.png 885w,\n/static/fb2d8a4decfa50838d6b5bcca2f5c92c/20c85/article3_image2.png 999w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Creditinfo Rating (CIR)"
    }), "\n", _jsx(_components.p, {
      children: "The Creditinfo Rating is a result of the analysis of all the company's credit and business related information which is stored in the database of the credit bureau. It is meant to shorten the information by summarizing it into a single number that is represented both qualitatively and quantitatively. The CIR Score is the quantitative measure which ranges from 0 to 130 where higher number is referred to the lower risk."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/a994d21b9f72fac1e6f86943451c7218/4b2cc/article3_image3.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 18.243243243243242%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAqElEQVQY012PbwrCMAzFdyXv4CX96E38IjuFMJjVMesqW12X/nvSsIxh4NH0NfklrWh4g14afhhAWmPRGikEpJyRUkIGkNdcPPZL/nd3zqGamwbu8cRXKcztHUvfI/kArCDvPZ97lRDI3mNgfThCXa5QxoBWUIlxHLlgmiZulnBuRtd1+BgDa+02WKDV7XSGbRVKC381RgYQEUII2zayUfHLkBgjS95EP33INpfbd+itAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Creditinfo Rating Example\"\n        title=\"\"\n        src=\"/static/a994d21b9f72fac1e6f86943451c7218/fcda8/article3_image3.png\"\n        srcset=\"/static/a994d21b9f72fac1e6f86943451c7218/12f09/article3_image3.png 148w,\n/static/a994d21b9f72fac1e6f86943451c7218/e4a3f/article3_image3.png 295w,\n/static/a994d21b9f72fac1e6f86943451c7218/fcda8/article3_image3.png 590w,\n/static/a994d21b9f72fac1e6f86943451c7218/efc66/article3_image3.png 885w,\n/static/a994d21b9f72fac1e6f86943451c7218/4b2cc/article3_image3.png 1013w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Inquiries"
    }), "\n", _jsx(_components.p, {
      children: "Credit report also provides information about number of inquiries made by financial institutions during specified time period and number of different financial institutions (subscribers ) who made an inquiry on the subject during specified time period. This information is also one of the factors when counting the final score. The indicator with a number of inquiries can be one of three different colours: green, orange and red, the latter means that there\nwere too many recent inquiries and serves as an alert of a potential fraudulent behavior."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/81fe93515443ed6c81ae4886c4affe28/fd84e/article3_image4.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 17.567567567567565%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAoklEQVQY032PwQrCQAxE96P0D9Vv8eRneFI8KniwdNlaBJFCxbZJ9smuKFLQwDDJDCQT9zjXmCoRiDFiZrwrzd9IniX+hln2VJWmaXD9pUIGyYKI5EX34c71cc2ayktP/fjA+FjXdThZz/H1jcqXFMUJaYXZbsF0NeEQ9oQiEELAlx5R/ST/VU6rDRJBZHglNNhetiyPS9q+xdQ+6RPnt//gCWKHNNiD2c5lAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Inquiries Example\"\n        title=\"\"\n        src=\"/static/81fe93515443ed6c81ae4886c4affe28/fcda8/article3_image4.png\"\n        srcset=\"/static/81fe93515443ed6c81ae4886c4affe28/12f09/article3_image4.png 148w,\n/static/81fe93515443ed6c81ae4886c4affe28/e4a3f/article3_image4.png 295w,\n/static/81fe93515443ed6c81ae4886c4affe28/fcda8/article3_image4.png 590w,\n/static/81fe93515443ed6c81ae4886c4affe28/efc66/article3_image4.png 885w,\n/static/81fe93515443ed6c81ae4886c4affe28/fd84e/article3_image4.png 1056w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Contracts"
    }), "\n", _jsx(_components.p, {
      children: "The Contracts section is a brief summary on the payment profile status of all the credit facilities linked to a borrower. It is a quick guide into a negative payment status of a particular account. After assessing the score, a lender may want to look at the specific contracts the subject of the report has entered into. The Payments Profile is aimed to display a summary of the relevant negative information on all loan contracts and is a useful tool a lender may use to make a decision whether investigating further each contract or not."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/65554261a22ab93f613dca39433745e8/7a3d6/article3_image5.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 33.108108108108105%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA50lEQVQoz3VRy47DIAzkC/u/+wG9VXvrLRJRymU3JIEI5QGYqexVomyUjjSykcEeD2p+vRCtBaOUAiICbfGKXDuSSN5O0yRUZB3WwSHltBe58Sdw7UxGSgkpJqiH+cZP/4vOdhiGYVfJF3LOQs5jjMLzMD7L3ZJBhaBuXzfc9R2+9XDeSZGla63RNI3QWgvnHLz3e8Ojur7roY3GkleoZ/dEWMO/qcuyoG1bITfjyOquVt7AA3MmqLM3jBACqqoSlXVdS26MwTzPlx6P4yh28XYq09/ux6ns4ebdlrNCjlefEvlDUpL8DfcTIRAm9YewAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Contracts Example\"\n        title=\"\"\n        src=\"/static/65554261a22ab93f613dca39433745e8/fcda8/article3_image5.png\"\n        srcset=\"/static/65554261a22ab93f613dca39433745e8/12f09/article3_image5.png 148w,\n/static/65554261a22ab93f613dca39433745e8/e4a3f/article3_image5.png 295w,\n/static/65554261a22ab93f613dca39433745e8/fcda8/article3_image5.png 590w,\n/static/65554261a22ab93f613dca39433745e8/efc66/article3_image5.png 885w,\n/static/65554261a22ab93f613dca39433745e8/7a3d6/article3_image5.png 990w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Collaterals"
    }), "\n", _jsx(_components.p, {
      children: "The collaterals section is a short summary of currently used collaterals for all the contracts the subject is involved to."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/7089ac0c62c3950222124cc7bb60abae/82b28/article3_image6.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 16.89189189189189%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAARElEQVQI153F2wnAMAwEQfffYQLuQQhkYb3OOB8pQAvDDhfBel6sOVERuFVV24gI2N5QVRDRh5nRbbg7RAT3ZvbLzJYDH+vsula7pNcAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Collaterals Example\"\n        title=\"\"\n        src=\"/static/7089ac0c62c3950222124cc7bb60abae/fcda8/article3_image6.png\"\n        srcset=\"/static/7089ac0c62c3950222124cc7bb60abae/12f09/article3_image6.png 148w,\n/static/7089ac0c62c3950222124cc7bb60abae/e4a3f/article3_image6.png 295w,\n/static/7089ac0c62c3950222124cc7bb60abae/fcda8/article3_image6.png 590w,\n/static/7089ac0c62c3950222124cc7bb60abae/efc66/article3_image6.png 885w,\n/static/7089ac0c62c3950222124cc7bb60abae/82b28/article3_image6.png 931w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Payment Incidents"
    }), "\n", _jsx(_components.p, {
      children: "This block includes information about delayed payments (invoices, rents, loans) reported by utilities, telecoms or other non-financial institutions."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/f0d1c91c274df805d68813548f1136bb/dc333/article3_image7.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 37.83783783783784%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABC0lEQVQoz41Ra4uEMAzs//+FfvNExVurrdVqa33MMjm6LAsLJ4Skk8nkoQpVhdj9gn5/PHADuO9b7Lousfz+hvHbtg0hBKikNZK1SMOAwzkhp5RwHIcQswjfIoS/78U7T8lRkG+ljcEWIwZjMHuPvu9RliWMMRKL1xp1XWNZFsG6rkPbtiiKAlpr/FSV4BRWTdPAOYdxGMQv3sNaKx3HccQ0TSJkrMW6roLN8yxc8mKMwuG6MqH3XpRJoAhjEuiZy6swT0/sPE/s+y4NeBI2JMZYcSV2YQEJJLIzizlJxtiEGIvJpzGW+4XwurViIh/5/e9dbz7HnIIrc+p8EmK5VgTzqP81TpRFGH/mnxoJbdqbEPOZAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Payment Incidents Example\"\n        title=\"\"\n        src=\"/static/f0d1c91c274df805d68813548f1136bb/fcda8/article3_image7.png\"\n        srcset=\"/static/f0d1c91c274df805d68813548f1136bb/12f09/article3_image7.png 148w,\n/static/f0d1c91c274df805d68813548f1136bb/e4a3f/article3_image7.png 295w,\n/static/f0d1c91c274df805d68813548f1136bb/fcda8/article3_image7.png 590w,\n/static/f0d1c91c274df805d68813548f1136bb/efc66/article3_image7.png 885w,\n/static/f0d1c91c274df805d68813548f1136bb/dc333/article3_image7.png 938w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Relations"
    }), "\n", _jsx(_components.p, {
      children: "The Relations block on Dashboard is showing if the subject is connected with any other subjects being a codebtor, guarantor, CEO, employee etc. More detailed information can be found in the Relations tab."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/7151502106992346741b86118b39cda1/264eb/article3_image8.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 17.567567567567565%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfUlEQVQY031PQQrDMAzL/7+Y9ZBbSCGXBXeJnajY1INtZQJhI4Elh77vWCJYa2HOafNjB2w3undD1VtrCK+cMY7DBIUfVIgImBkO924JgIgQnkR4bBtSSiYoxhiWVkpBjBE5Z9Raf0K/qQjCbO9oE23kzXrv71fV05C/DS+eNM06uV+0/0IAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Relations Example\"\n        title=\"\"\n        src=\"/static/7151502106992346741b86118b39cda1/fcda8/article3_image8.png\"\n        srcset=\"/static/7151502106992346741b86118b39cda1/12f09/article3_image8.png 148w,\n/static/7151502106992346741b86118b39cda1/e4a3f/article3_image8.png 295w,\n/static/7151502106992346741b86118b39cda1/fcda8/article3_image8.png 590w,\n/static/7151502106992346741b86118b39cda1/264eb/article3_image8.png 867w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Creditinfo Quest (CIQ)"
    }), "\n", _jsx(_components.p, {
      children: "Creditinfo Quest block represents information which is relevant according to the system in the meaning of a possible fraud. The CIQ includes information about inquires made during last 48 hours or 14 days as too many inquires might look suspicious, Lost/Stolen documents information in case such information is accessible and data about contracts closed with the status \"canceled\" which means that a loan was taken by a fraudster but found out and canceled by the financial institution."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/e807fa173174dd758484157b1e8740bc/65d79/article3_image9.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 17.567567567567565%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVQY04WP2woCMQxE+/8/J/i8Prl1FVNtXOym11kMVnzwMnDIkMtATBgtFmsR7AH3/YhaClprqLVq7V55+E88d5kZRpzDQoTMjHz1aO9BpehyV+9/Q0RgTpstRjqDiCAp6eGNGd57HKcJu2HQWc75FfpLRtwFBQ1RBCklPYgxKvM8wxEhhIDS3/7DCtt1ORGeE+yQAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Creditinfo Quest Example\"\n        title=\"\"\n        src=\"/static/e807fa173174dd758484157b1e8740bc/fcda8/article3_image9.png\"\n        srcset=\"/static/e807fa173174dd758484157b1e8740bc/12f09/article3_image9.png 148w,\n/static/e807fa173174dd758484157b1e8740bc/e4a3f/article3_image9.png 295w,\n/static/e807fa173174dd758484157b1e8740bc/fcda8/article3_image9.png 590w,\n/static/e807fa173174dd758484157b1e8740bc/efc66/article3_image9.png 885w,\n/static/e807fa173174dd758484157b1e8740bc/65d79/article3_image9.png 905w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Disputes"
    }), "\n", _jsx(_components.p, {
      children: "Disputes block shows summary information about the claims about any errors on inconsistencies on the report."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/8d6116186a77356f6c30bfca185dc835/2e195/article3_image10.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 16.89189189189189%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAiUlEQVQI121NQQ4CIQzkmz7B33j2Gd58gwdv/mANLoHstoBAhDFtwm2bNDOZmU5Ni4z8XvBjxpwxBo7mSJ9aSknX7I8nlssVm7Vw3qN8iwZaa6i1YN82uNWBmdF7R61VN3iP4IPyWSy+WW93vE5nsP2AUkLOWQOCMUb9SkTK5UBQPCZSFE3KZuEfOjfnWA7Kq8sAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Disputes Example\"\n        title=\"\"\n        src=\"/static/8d6116186a77356f6c30bfca185dc835/fcda8/article3_image10.png\"\n        srcset=\"/static/8d6116186a77356f6c30bfca185dc835/12f09/article3_image10.png 148w,\n/static/8d6116186a77356f6c30bfca185dc835/e4a3f/article3_image10.png 295w,\n/static/8d6116186a77356f6c30bfca185dc835/fcda8/article3_image10.png 590w,\n/static/8d6116186a77356f6c30bfca185dc835/2e195/article3_image10.png 782w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
